<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>
          {{ candidate.firstName }} {{ candidate.surname }}
        </BasePageHeading>
        <p class="text-gray-600">
          {{ candidate.email }}
        </p>
      </div>

      <BasePageActions>
        <DeleteCandidateButton
          :candidates="[...candidate]"
          @candidates-deleted="candidateDeleted()"
        />

        <EditCandidateButton
          :candidate="candidate"
          @candidateUpdated="candidateUpdated()"
        />

        <DownloadCandidateProfileButton
          :candidate="candidate"
        />
      </BasePageActions>
    </BasePageHeader>

    <BaseWrapper>
      <ul class="space-y-6">
        <li
          v-for="(exam, index) in examsSortedByAttemptDate"
          :key="index"
        >
          <BaseCard
            no-padding
          >
            <CandidateExamAndResult
              :candidate="candidate"
              :exam="exam"
              :index="index"
            />
          </BaseCard>
        </li>
      </ul>
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
// Components
import DeleteCandidateButton from '@components/Candidates/DeleteCandidateButton'
import DownloadCandidateProfileButton from '@components/Candidates/DownloadCandidateProfileButton'
import EditCandidateButton from '@components/Candidates/EditCandidateButton'
import Loader from '@components/Loader'
import CandidateExamAndResult from '@components/Candidates/CandidateExamAndResult'

// Utilities
import jobCandidatesApi from '@api/jobCandidates'
import states from '@api/states'
import { mapGetters } from 'vuex'

export default {
  components: {
    CandidateExamAndResult,
    DeleteCandidateButton,
    DownloadCandidateProfileButton,
    EditCandidateButton,
    Loader
  },

  data() {
    return {
      states,

      error: null,
      candidate: null,
      job: null
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name',
      haveExamsLoaded: 'exams/haveExamsLoaded',
      exams: 'organisations/exams',
      organisationId: 'employers/organisationId'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Assessments',
          to: 'client-assessments-list'
        },
        {
          name: this.job.name,
          to: 'client-assessments-show',
          params: {
            id: this.job.uuid
          }
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.candidate || !this.job) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Array}
     */
    examsSortedByAttemptDate() {
      if (!this.job.exams) {
        return []
      }

      let examsAndAttempts = []

      this.job.exams.forEach(exam => {
        this.candidate.attempts
          .filter(attempt => attempt.examSlug === exam.slug)
          .forEach(attempt => {
            examsAndAttempts.push({
              ...exam,
              attempt: attempt
            })
          })
      })

      const more = examsAndAttempts.sort((a, b) => {
        return Object.entries(b.attempt).length !== 0 ? 1 : -1
      })

      return more.sort((a, b) => {
        return new Date(b.attempt.createdAt) - new Date(a.attempt.createdAt)
      })
    }
  },

  created() {
    this.fetchCandidateAssessment()
  },

  methods: {
    /**
     * Fetch the assessment based on the ID
     *
     * @return {Promise}
     */
    fetchCandidateAssessment() {
      this.candidate = null
      this.job = null

      return jobCandidatesApi.show(this.$route.params.id, this.$route.params.candidate)
        .then(response => {
          this.candidate = response.data
          this.job = response.meta.job
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * A candidate has been deleted
     */
    candidateDeleted() {
      this.$router.push({ name: 'candidates' })
    },

    /**
     * A candidate has been deleted
     */
    candidateUpdated() {
      this.fetchCandidateAssessment()
    }
  }
}
</script>
