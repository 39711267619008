import { pickedApiRequest } from './request'

export default {
  /**
   * Get all candidates for an assessment
   *
   * @param {string} id
   * @param {Object} job
   * @return {Object}
   */
  index (jobId, pageNumber) {
    return pickedApiRequest.get('assessment/jobs/' + jobId + '/candidates?page=' + pageNumber)
      .then(response => {
        return response.data
      })
  },

  /**
   * Get a candidate’s assessment
   *
   * @param {string} id
   * @param {Object} job
   * @return {Object}
   */
  show (jobId, candidateId) {
    return pickedApiRequest.get('assessment/jobs/' + jobId + '/candidates/' + candidateId)
      .then(response => {
        return response.data
      })
  }
}
