<template>
  <div v-if="examHasAttemptAndIsUnlocked">
    <AptitudeExamResults
      v-if="hasTakenScoreExam"
      :icon="dynamicIcon"
      :candidate="candidate"
      :exam="exam"
      :index="index"
    />

    <DrivesExamResults
      v-if="hasTakenDrivesExam"
      :icon="dynamicIcon"
      :exam="exam"
    />

    <PersonalityExamResults
      v-if="hasTakenPersonalityExam"
      :icon="dynamicIcon"
      :exam="exam"
    />

    <CustomExamResults
      v-if="hasTakenCustomExam"
      :candidate="candidate"
      :exam="exam"
    />
  </div>
  <div v-else>
    <p class="p-6 text-sm">
      We’re unable to show you this test result as it has not been unlocked
    </p>
  </div>
</template>

<script>
import AptitudeExamResults from '@components/Candidates/AptitudeExamResults'
import DrivesExamResults from '@components/Candidates/DrivesExamResults'
import PersonalityExamResults from '@components/Candidates/PersonalityExamResults'
import CustomExamResults from '@components/Candidates/CustomExamResults'

import { examIcons } from '@data/examIcons'
import camelCase from 'lodash/camelCase'

export default {
  components: {
    AptitudeExamResults,
    DrivesExamResults,
    PersonalityExamResults,
    CustomExamResults
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    index: {
      type: Number
    }
  },

  data() {
    return {
      examIcons
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    examHasAttemptAndIsUnlocked() {
      return (Object.entries(this.exam.attempt).length !== 0) && this.exam.attempt.unlockedAt
    },

    /**
     * @return {Boolean}
     */
    hasTakenScoreExam() {
      return this.exam.attempt.scorePercentage !== null
    },

    /**
     * @return {Boolean}
     */
    hasTakenPersonalityExam() {
      return (this.exam.attempt.examSlug.includes('personality')) && (this.exam.attempt.result === null)
    },

    /**
     * @return {Boolean}
     */
    hasTakenDrivesExam() {
      return this.exam.attempt.examSlug.includes('drives')
    },

    /**
     * @return {Boolean}
     */
    hasTakenCustomExam() {
      return !!this.exam.organisationId
    },

    /**
     * @return {Object}
     */
    dynamicIcon() {
      if (!this.exam.icon) {
        return () => import('@components/Icons/' + this.examIcons[this.exam.slug])
      }
      // Convert from lebab-case to UpperCamelCase
      let icon = camelCase(this.exam.icon)
      icon = icon.charAt(0).toUpperCase() + icon.slice(1)
      return () => import('@components/Icons/Exams/' + icon)
    }
  }
}
</script>
